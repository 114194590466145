import React, { useState } from 'react';
import './Navbar.css'; // Import your CSS file
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import logo from './images/logo.png';

const CustomNavbar = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => {
    setShowOffcanvas(prev => !prev);
  };

  return (
    <>
      <Navbar expand="lg" className="custom-navbar" fixed="top">
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="90em"
            width="auto"
            href="/"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleOffcanvas} style={{marginRight: '0.8em'}}/>
        <Navbar.Collapse id="basic-navbar-nav" style={{marginRight: '1em', marginLeft: '2em'}} className="justify-content-end">
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about-us">About Us</Nav.Link>
            <Nav.Link href="/R&D">Research and Development</Nav.Link>
            <Nav.Link href="/career">Careers</Nav.Link>
            <Nav.Link href="/news">News</Nav.Link>
            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default CustomNavbar;