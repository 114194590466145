import React, {useEffect} from "react"
import MyNavbar from './Navbar';
import Footer from './Footer';
import missionImage from './images/mission&vision.jpg'
import './About.css'
import Accordion from 'react-bootstrap/Accordion';
import ScrollReveal from 'scrollreveal';
import { Container, Row, Col } from "react-bootstrap";

function About () {

    useEffect(() => {
        // Create a new instance of ScrollReveal
        const sr = ScrollReveal({
          duration: 1000, // Animation duration in milliseconds
          reset: true, // Reset reveal animations when they are no longer in the viewport
          delay: 500, // Delay between revealing elements in milliseconds
          distance: '20px', // Distance the element will travel in the reveal animation
        });
    
        // Define the elements to reveal
        const elementsToReveal = ['.number-start', '.title-start', '.description-start'];
    
        // Loop through each element and apply the reveal animation
        elementsToReveal.forEach((selector) => {
          sr.reveal(selector);
        });
    
        // Cleanup the ScrollReveal instance when the component unmounts
        return () => {
          sr.destroy();
        };
      }, []);

    return(
        <div>
            <MyNavbar />
            <div className="background">
                <div className="center-text">
                    <h2 className="background-text">About Us</h2>
                </div>
            </div>
            <div className="main-about-div-content">
                <div className="left-about-us">
                    <h2><p className="title-start">Our Story</p></h2>
                    <p className="description-start">Welcome to Metayu Pharmatech, a pioneering startup established in December 2023, positioned at the crossroads of tradition and innovation. The essence of our identity, 'Metayu', is a thoughtful blend of two concepts: 'Meta', representing metamorphosis—a natural process of transformation and evolution, and 'Ayu', signifying Ayurveda, the ancient and esteemed Indian medicinal system. This fusion embodies our dedication to integrating the timeless wisdom of Ayurvedic practices with the dynamic advancements of modern science, setting a new paradigm in healthcare.</p>
                </div>
                <div style={{display: 'flex'}}>
                    <div className="empty-right-about"></div>
                    <div className="right-about-us">
                        <h2><p className="title-start">Traditional-modern Fusion</p></h2>
                        <p className="description-start">At the core of Metayu Pharmatech is our commitment to the extensive research and development of pharmaceutical and cosmeceutical products. Our approach is not just about blending science with nature, but about pioneering new paths in healthcare solutions. We delve deep into the intricacies of both fields, striving to uncover innovative formulations and therapeutic options that resonate with both the body and the environment.</p>
                    </div>
                </div>
                <div className="left-about-us">
                    <h2><p className="title-start">Innovative Research</p></h2>
                    <p className="description-start">Our expertise in product formulation is comprehensive, covering a broad spectrum from pharmaceuticals to cosmeceuticals. We pride ourselves on our ability to offer holistic solutions that span the entire product development lifecycle—from conceptualization and research to formulation and production. Our team works tirelessly to ensure that every product not only meets but exceeds the highest standards of efficacy, safety, and quality.</p>
                </div>
                <div style={{display: 'flex'}}>
                    <div className="empty-right-about"></div>
                    <div className="right-about-us">
                        <h2><p className="title-start">Natural Harmony</p></h2>
                        <p className="description-start">Excitingly, we are on the verge of launching our own line of cosmeceutical products. These have been crafted with the utmost care, embodying our vision of harmonizing the natural and the scientific to create products that truly make a difference in the lives of our consumers. Each product is a testament to our dedication to innovation, quality, and sustainability, promising to offer a unique and enriching experience.</p>
                    </div>
                </div>
                <div className="left-about-us">
                    <h2><p className="title-start">Our Ambition</p></h2>
                    <p className="description-start">At Metayu Pharmatech, our ambitions is to extend beyond cosmeceuticals. Metayu Pharmatech is also deeply invested in the research and development of pharmaceuticals, with a keen focus on novel drug delivery systems. Our aim is to revolutionize the way medicines are delivered, enhancing their effectiveness, reducing side effects, and improving patient outcomes. Through our research, we aspire to unveil groundbreaking delivery mechanisms that can transform the therapeutic landscape.</p>
                </div>
                <div style={{display: 'flex'}}>
                    <div className="empty-right-about"></div>
                    <div className="right-about-us">
                        <h2><p className="title-start">Join Us</p></h2>
                        <p className="description-start">Join us at Metayu Pharmatech as we embark on a journey to redefine healthcare. Our commitment to research, innovation, and sustainability is unwavering, as we strive to bring about a metamorphosis in the health and well-being of communities around the globe. Discover the power of transformation with us, where ancient wisdom meets modern science in a quest for healthier, happier lives.</p>
                    </div>
                </div>
            </div>
            <div className="our-mission-div">
                <div className="our-mission-image">
                    <img src={missionImage} alt="Metayu Vision" className="mission-image" />
                </div>
                <div className="mission-accordation">
                <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><p className="accordation-heading">Our Mission</p></Accordion.Header>
                    <Accordion.Body>
                    <p className="accordation-text">At Metayu Pharmatech, our mission is to revolutionize the integration of Ayurvedic 
                    wisdom and modern scientific research to develop innovative pharmaceutical and cosmeceutical 
                    products. We are dedicated to pioneering advancements in healthcare solutions, focusing on 
                    natural transformation and holistic well-being. Our commitment extends to rigorous research, 
                    sustainable practices, and the highest standards of product formulation. We strive to empower 
                    individuals with effective, natural, and safe healthcare options, fostering a healthier future 
                    for all.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><p className="accordation-heading">Our Vision</p></Accordion.Header>
                    <Accordion.Body>
                    <p className="accordation-text">Our vision is to become a global leader in the pharmaceutical and cosmeceutical industry 
                    by setting new benchmarks for innovation, quality, and sustainability. We aim to redefine 
                    healthcare by bridging the gap between traditional Ayurvedic practices and contemporary 
                    scientific methodologies. Through our unwavering dedication to research and development, 
                    Metayu Pharmatech envisions a world where natural healing and modern technology converge to 
                    offer transformative health solutions, enhancing the quality of life across communities 
                    worldwide.</p>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                </div>
            </div>
            <div className="main-about-div-content">
                <h2>BOARD OF DIRECTORS</h2>
                <Container>
                    <Row>
                        <Col style={{padding: '3em 1em'}}>
                            <p>Mr. Samruddha R. Wavikar</p>
                            <p style={{fontWeight: '1000', marginTop: '-1.2em'}}>Director and C.E.O.</p>
                        </Col>
                        <Col style={{padding: '3em 1em'}}>
                            <p>Mr. Kaustubh S. Joshi</p>
                            <p style={{fontWeight: '1000', marginTop: '-1.2em'}}>Director and C.F.O.</p>    
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{padding: '0 1em 3em'}}>
                            <p>Dr. Mrs. Gayatri R. Wavikar</p>
                            <p style={{fontWeight: '1000', marginTop: '-1.2em'}}>Director</p>    
                        </Col>
                        <Col style={{padding: '0 1em 3em'}}>
                            <p>Mrs. Sneha Joshi</p>
                            <p style={{fontWeight: '1000', marginTop: '-1.2em'}}>Director</p>    
                        </Col>
                    </Row>
                </Container>    
            </div> 
            <Footer />
        </div>
    )
}

export default About